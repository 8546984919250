import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#070607",
    },
    secondary: {
      main: "#8F00FF",
    },
  },
  typography: {
    fontFamily: `Space Grotesk, sans-serif`,
    h2: {
      color: "white",
    },
  },
  components: {},
});

export default theme;
