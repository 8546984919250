import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import QRCode from "qrcode.react";

import {
  DialogContent,
  Box,
  OutlinedInput,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { PurpleButton } from "../../components/Buttons/Buttons.component";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { closeMintWindow } from "../../redux/settings.slice";
import { RootState } from "../../redux/store";
import useFeeRate from "../../hooks/feerate.hook";
import { Token } from "../../redux/token.slice";
import MintForm from "../MintForm/MintForm.component";
import PaymentComponent from "../Payment/Payment.component";
import axios from "axios";

function TokenComponent({ token }: { token: Token }) {
  const [isPayment, setisPayment] = React.useState(false);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state: RootState) => state.settings.mint.isMinting
  );

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [walletDetails, setWalletDetails] = React.useState(undefined);

  //   const token = useAppSelector((state: RootState) => state.settings.mint.token);

  const ordinalsAddress = useAppSelector(
    (state: RootState) => state.user.ordinalsAddress
  );
  const [totalMinting, setTotalMinting] = React.useState(1);

  const handleClose = () => {
    dispatch(closeMintWindow());
    setisPayment(false);
    // setReceiverAddress(ordinalsAddress);
  };

  const handleSubmit = async (receiverAddress: any, total: any) => {
    setLoading(true);
    try {
      const walletResp = await fetch("/api/mint/generate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          receiver: receiverAddress,
          token: token._id,
          amount: total,
        }),
      });
      const wallet = await walletResp.json();
      console.log(wallet);
      if (!wallet) {
        throw new Error("Error generating wallet");
      }
      setWalletDetails(wallet);
      setTotalMinting(total);
      setisPayment(true);
    } catch (error) {
      console.log(error);
      setError((error as any).toString());
    }
    setLoading(false);
  };

  const submitPayment = async() => {
    try {
      // const res = await fetch("/api/token/mint", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     receiver: receiverAddress,
      //     token: token._id,
      //     amount: total,
      //   }),
      // });
      // // const data = await res.json();
      // // if (res.status === 201) {
      // //   setisPayment(true);
      // //   setTotalMinting(total);
      // // }
    } catch (error) {
      console.log(error);
    }
  };

  if (!token) {
    return (
      <Box>
        <Typography variant="h6" textAlign={"center"}>
          Token not found
        </Typography>
      </Box>
    );
  }
  // const [data, setData] = React.useState<any>(null);

  if (isPayment)
    return (
      <PaymentComponent
        handleClose={handleClose}
        token={token}
        totalMinting={totalMinting}
        walletDetails={walletDetails}
      />
    );

  return (
    <MintForm
      token={token}
      handleSubmit={handleSubmit}
      loading={loading}
      error={error}
    />
  );
}

export default TokenComponent;
