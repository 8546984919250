import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Token } from "../../redux/token.slice";
import { InputAdornment, OutlinedInput, useTheme } from "@mui/material";
import Adorment from "../../assets/images/div.svg";

export function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: NodeJS.Timeout;
  return function (...args: any[]) {
    //@ts-ignore
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const SearchInput = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<Token[]>([]);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const anchorEl = useRef<HTMLDivElement | null>(null);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSearch = debounce(async (value: string) => {
    if (value) {
      const response = await axios.get<Token[]>(
        `/api/tokens/search?query=${value}`
      );
      setResults(response.data);
      setMenuOpen(true);
    } else {
      setResults([]);
      setMenuOpen(false);
    }
  }, 300);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setQuery(value);
    handleSearch(value);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };


  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);


  return (
    <div ref={anchorEl}>
      <OutlinedInput
        ref={inputRef}
        id="outlined-adornment-password"
        type={"search"}
        sx={{
          background: "#0E0E0F",
          backdropFilter: "blur(7px)",
          width: "100%",
          ".MuiOutlinedInput-input": {
            color: "rgba(255, 255, 255, 0.6)",
          },
          border: "1px solid rgba(255, 255, 255, 0.17)",
          borderRadius: "8px",
          "&::placeholder": {
            color: "gray",
            fontStyle: "italic",
          },
        }}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {}}
              onMouseDown={() => {}}
              edge="end"
            >
              <img src={Adorment} alt="search button" />
            </IconButton>
          </InputAdornment>
        }
        label="Password"
        placeholder="Search Platform Collections"
      />
      <Menu anchorEl={anchorEl.current} open={menuOpen} onClose={handleClose}>
        {results.map((item) => (
          <MenuItem key={item._id} onClick={handleClose}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

// export default SearchInput;
export default React.memo(SearchInput);

