import React, { useEffect } from "react";

import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { RootState } from "../../redux/store";
import { fetchApplications } from "../../redux/tokenApplication.slice";
import Application from "../Application/Application.component";

const ApplicationsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const applications = useAppSelector(
    (state: RootState) => state.applications.data
  );
  const fetchApplicationsStatus = useAppSelector(
    (state: RootState) => state.applications.fetchApplications.status
  );
  const fetchApplicationsError = useAppSelector(
    (state: RootState) => state.applications.fetchApplications.error
  );

  useEffect(() => {
    if (fetchApplicationsStatus === "idle") {
      dispatch(fetchApplications());
    }
  }, [fetchApplicationsStatus, dispatch]);

  let content;

  if (fetchApplicationsStatus === "loading") {
    content = <CircularProgress />;
  } else if (fetchApplicationsStatus === "succeeded") {
    content = (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography color="white">Name</Typography>
              </TableCell>
              <TableCell>
                <Typography color="white">Social</Typography>
              </TableCell>
              <TableCell>
                <Typography color="white">handle</Typography>
              </TableCell>
              <TableCell>
                <Typography color="white">Release Date</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.map((application) => (
              <Application
                application={application}
                key={(application as any)._id}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (fetchApplicationsStatus === "failed") {
    content = (
      <Typography color="white">
        {fetchApplicationsError && (fetchApplicationsError as any).message
          ? (fetchApplicationsError as any)?.message
          : fetchApplicationsError}
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" sx={{ marginBottom: 2, color: "white" }}>
        Applications
      </Typography>
      {content}
    </Box>
  );
};

export default ApplicationsList;
