import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import QueueStatus from "../../components/Queue/Queue.component";
import TokenComponent from "../../components/Token/Token.component";
import { Token } from "../../redux/token.slice";
import { useParams } from "react-router-dom";

const TokenPage = () => {
  const { id } = useParams(); // This will give you the token ID from the route
  const [token, setToken] = useState<Token | null>(null);
  const [queuePosition, setQueuePosition] = useState<number | null>(null);
  const [isInQueue, setIsInQueue] = useState<boolean>(false);

  const fetchToken = async () => {
    try {
      const response = await fetch(`/api/token/${id}`);
      const data = await response.json();

      if (response.status === 200) {
        setToken(data);
        setIsInQueue(false);
      } else if (response.status === 202) {
        setQueuePosition(data.position);
        setIsInQueue(true);
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  useEffect(() => {
    fetchToken();

    // If the user is in a queue, poll the backend every 10 seconds to check for updates
    if (isInQueue) {
      const interval = setInterval(fetchToken, 10000);
      return () => clearInterval(interval); // Cleanup on component unmount
    }
  }, [id, isInQueue]);

  if (token) {
    return (
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        marginTop={"5rem"}
        flexDirection={"column"}
      >
        <Typography variant={"h2"}>Mint by {token.name}</Typography>
        <Divider sx={{ width: "100%", margin: "2rem 0" }} />
        <TokenComponent token={token} />
      </Box>
    );
  } else if (queuePosition !== null) {
    return <QueueStatus position={queuePosition} />;
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
};

export default TokenPage;
