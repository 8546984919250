import { useState, useEffect } from "react";

export interface Inscription {
  inscriptionId: string;
  inscriptionNumber: number;
  address: string;
  outputValue: number;
  preview: string;
  content: string;
  contentType: string;
  contentLength: number;
  timestamp: number;
  genesisTransaction: string;
  location: string;
  output: string;
  offset: number;
  contentBody: string;
}

function useGetInscriptionsByAddress(
  address = "bc1phs36vxaa35v7rc5sky353sj39n5vakl62lcd6nn7k8yql05v6a8q9x4jzl"
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Inscription[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [cursor, setCursor] = useState<number>(0);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://unisat.io/wallet-api-v4/address/inscriptions?address=${address}&cursor=${cursor}&size=30`,
        {
          headers: {
            "X-Client": "UniSat Wallet",
          },
        }
      );
      const json = await response.json();

      setData((prevData) => [...prevData, ...json.result.list]);
      setCursor(json.result.total);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [cursor]);

  return { data, loading, error, fetchNextPage: () => setCursor(cursor + 1) };
}

export default useGetInscriptionsByAddress;
