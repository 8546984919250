import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/images/div_logo.svg";
import { InputAdornment, OutlinedInput, useTheme } from "@mui/material";
import Adorment from "../../assets/images/div.svg";
import GradientBorderButton from "../Buttons/Buttons.component";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { openApplyDialog } from "../../redux/settings.slice";
import { RootState } from "../../redux/store";
import { GetAddressOptions, getAddress } from "sats-connect";
import { setOrdinalsAddress, setPaymentAddress } from "../../redux/user.slice";
import SearchInput from "../Search/Search.component";

const pages = [
  {
    name: "Curation",
    link: "/releases",
  },
  {
    name: "Services",
    link: "/services",
  },
];

function ResponsiveAppBar() {
  const dispatch = useAppDispatch();
  const [isHovering, setIsHovering] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const ordinalsAddress = useAppSelector(
    (state: RootState) => state.user.ordinalsAddress
  );

  const onConnectClick = async () => {
    const getAddressOptions = {
      payload: {
        purposes: ["ordinals", "payment"],
        message: "Address for receiving Ordinals",
        network: {
          type: "Mainnet",
        },
      },
      onFinish: (response: any) => {
        dispatch(setOrdinalsAddress(response.addresses[0].address));
        dispatch(setPaymentAddress(response.addresses[1].address));
      },
      onCancel: () => alert("Request canceled"),
    };
    await getAddress(getAddressOptions as GetAddressOptions);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const mobileMenu = (
    <Menu
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={mobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.main, // Set your preferred color here
          color: "#eeee",
          background:
            "linear-gradient(#141414, #141414) padding-box,\n        linear-gradient(60deg, #885CE7, #E8DDFF) border-box",
          padding: "10px",
          width: "80%",
          border: ".1px solid transparent",
          borderRadius: "40px",
          //   display: "inline-block",
          fontSize: "12px",
        },
      }}
    >
      <MenuItem>
        <OutlinedInput
          id="outlined-adornment-password-mobile"
          type={"search"}
          sx={{
            background: "#0E0E0F",
            backdropFilter: "blur(7px)",
            width: "100%",
            ".MuiOutlinedInput-input": {
              color: "rgba(255, 255, 255, 0.6)",
            },
            border: "1px solid rgba(255, 255, 255, 0.17)",
            borderRadius: "8px",
            "&::placeholder": {
              color: "gray",
              fontStyle: "italic",
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {}}
                onMouseDown={() => {}}
                edge="end"
              >
                <img src={Adorment} alt="search button" />
              </IconButton>
            </InputAdornment>
          }
          label="Password"
          placeholder="Search Platform Collections"
        />
      </MenuItem>
      {pages.map((page) => (
        <MenuItem onClick={() => navigate(page.link)} key={page.name}>
          {page.name}
        </MenuItem>
      ))}
      <MenuItem onClick={() => dispatch(openApplyDialog())}>Apply </MenuItem>
      <MenuItem>
        <GradientBorderButton
          onClick={() => {
            if (ordinalsAddress) {
              navigate("/dashboard");
            } else {
              onConnectClick();
            }
          }}
        >
          {ordinalsAddress
            ? `${ordinalsAddress.slice(0, 3)}...${ordinalsAddress.slice(-3)}`
            : "Connect"}
        </GradientBorderButton>
      </MenuItem>
    </Menu>
  );

  const logoutMenue = (
    // <Menu
    //   anchorOrigin={{ vertical: "top", horizontal: "right" }}
    //   transformOrigin={{ vertical: "top", horizontal: "right" }}
    //   open={!mobileMenuOpen && isHovering}
    //   onClose={handleMobileMenuClose}
    //   PaperProps={{
    //     style: {
    //       backgroundColor: theme.palette.primary.main, // Set your preferred color here
    //       color: "#eeee",
    //       background:
    //         "linear-gradient(#141414, #141414) padding-box,\n        linear-gradient(60deg, #885CE7, #E8DDFF) border-box",
    //       padding: "10px",
    //       width: "80%",
    //       border: ".1px solid transparent",
    //       borderRadius: "40px",
    //       //   display: "inline-block",
    //       fontSize: "12px",
    //     },
    //   }}
    // >
    //   <MenuItem onClick={() => dispatch(openApplyDialog())}>Apply </MenuItem>
    // </Menu>
    <></>
  );

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        background: theme.palette.primary.main,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          style={{
            minHeight: "100px",
          }}
        >
          <IconButton onClick={() => navigate("/")}>
            <img width={"120px"} src={Logo} alt="Logo" />
          </IconButton>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              width: "30%",
            }}
          >
            <SearchInput />
          </Box>

          <Box m={2} />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  textTransform: "none",
                }}
                onClick={() => navigate(page.link)}
              >
                {page.name}
              </Button>
            ))}

            <Button
              sx={{
                my: 2,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
              onClick={() => dispatch(openApplyDialog())}
            >
              Apply
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <GradientBorderButton
              onClick={() => {
                if (ordinalsAddress) {
                  navigate("/dashboard");
                } else {
                  onConnectClick();
                }
              }}
              onMouseEnter={() => setIsHovering(true)}
            >
              {ordinalsAddress
                ? `${ordinalsAddress.slice(0, 6)}...${ordinalsAddress.slice(
                    -6
                  )}`
                : "Connect"}
            </GradientBorderButton>
          </Box>
        </Toolbar>
      </Container>
      {mobileMenu}
      {logoutMenue}
    </AppBar>
  );
}

export default ResponsiveAppBar;
