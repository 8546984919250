import { Box, Container } from "@mui/material";
import React from "react";
import ApplicationsList from "../../components/Applications/Applications.component";
import NewTokenForm from "../../components/NewTokenForm/NewTokenForm.components";

const ApplicationsPage = () => {
  return (
    <Box
      sx={{
        // background: theme.palette.primary.main,
        minHeight: { xs: "90vh", md: "100vh" },
        width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // background:"red",
        paddingTop: { md: "50px", xs: "50px" },
        color: "#eee ",
        // overflow:"scroll"
      }}
    >
      <Container maxWidth="xl">
        <NewTokenForm />
        <ApplicationsList />
      </Container>
    </Box>
  );
};
export default ApplicationsPage;
