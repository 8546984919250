import {
  TokenApplication,
  deleteApplication,
} from "../../redux/tokenApplication.slice";
import { useState } from "react";

import {
  Box,
  Typography,
  TableCell,
  TableRow,
  Collapse,
  IconButton,
  Grid,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { RootState } from "../../redux/store";

const Application = ({ application }: { application: TokenApplication }) => {
  const dispatch = useAppDispatch();
  const deleteApplicationStatus = useAppSelector(
    (state: RootState) => state.applications.deleteApplication.status
  );
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
        key={(application as any)._id}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <Typography color="white">{application.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography color="white">{application.social}</Typography>
        </TableCell>
        <TableCell>
          <Typography color="white">{application.handle}</Typography>
        </TableCell>

        <TableCell>
          <Typography color="white">
            {dayjs(application.date).format("MM/DD/YYYY")}
          </Typography>
        </TableCell>

        {/* Add other cells as needed */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>

              <Grid container>
                {Object.keys(application).map((key) => (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography color={"grey"} variant="subtitle1" gutterBottom>
                      {key}
                    </Typography>
                    <Typography variant="body2" gutterBottom color={"white"}>
                      {typeof (application as any)[key] === "boolean"
                        ? (application as any)[key]
                          ? "true"
                          : "false"
                        : (application as any)[key]}
                    </Typography>
                  </Grid>
                ))}
              </Grid>

              <Box display={"flex"} justifyContent={"flex-end"}>
                {" "}
                <IconButton
                  onClick={() => {
                    if (deleteApplicationStatus !== "loading") {
                      dispatch(deleteApplication((application as any)._id));
                    }
                  }}
                >
                  <DeleteForeverIcon sx={{ color: "white" }} />
                </IconButton>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Application;
