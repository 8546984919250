import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  OutlinedInput,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { RootState } from "../../redux/store";
import { Token, createToken } from "../../redux/token.slice";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PurpleButton } from "../Buttons/Buttons.component";
import { sendNotification } from "../../utils/sendNotification";

const inputStyles = {
  background: "#0E0E0F",
  backdropFilter: "blur(7px)",
  width: "100%",
  ".MuiOutlinedInput-input": {
    color: "#ffffff",
  },
  border: "1px solid rgba(255, 255, 255, 0.17)",
  borderRadius: "8px",
  color: "#ffffff",

  "&::placeholder": {
    color: "#ffffff",
    fontStyle: "italic",
  },
};

const StyledInputLabel = styled(InputLabel)({
  color: "#ffffff",
});

const NewTokenForm = () => {
  const [token, setToken] = useState<Token>({
    name: "",
    social: "",
    handle: "",
    ticker: "",
    supply: undefined,
    minted: undefined,
    cost: 0,
    max: undefined,
    date: undefined,
    wallet: "",
  });

  const status = useAppSelector(
    (state: RootState) => state.tokens.addState.status
  );
  const error = useAppSelector(
    (state: RootState) => state.tokens.addState.error
  );

  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToken({ ...token, [event.target.id]: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(createToken(token));
    sendNotification("", "success", "New Token added to curations");
  };

  let errorMessage: JSX.Element | null = null;
  if (status === "failed") {
    errorMessage = (
      <Typography color={"red"} textAlign={"center"}>
        {status && (status as any).message ? (status as any)?.message : status}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        // background:theme.palette.primary.main,
        background:
          "linear-gradient(#0D0b10, #0D0b10) padding-box,\n        linear-gradient(60deg, #885CE7, #E8DDFF) border-box",
        color: "#eee",
        border: {
          md: ".2px solid transparent",
        },
        borderRadius: "20px",
        padding: "10px",
      }}
    >
      <Typography variant="h3">Create A New Release</Typography>
      <form onSubmit={handleSubmit}>
        {" "}
        <OutlinedInput
          id="name"
          type={"name"}
          sx={inputStyles}
          label="Name"
          placeholder="Name Of Project"
          onChange={handleChange}
          value={token.name}
        />
        <Box sx={{ height: "12px" }} />
        <Box sx={{ height: "12px" }} />
        <Box display="flex">
          <FormControl fullWidth>
            <InputLabel
              sx={{ color: "rgba(64,64,64,1)" }}
              id="demo-simple-select-label"
            >
              Social
            </InputLabel>
            <Select
              sx={inputStyles}
              labelId="demo-simple-select-label"
              id="social"
              label="Social"
              onChange={(e: any) =>
                setToken({ ...token, social: e.target.value })
              }
              value={token.social}
            >
              <MenuItem value={"Twitter"}>Twitter</MenuItem>
              <MenuItem value={"Discord"}>Discord</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ width: "15px" }} />
          <OutlinedInput
            id="handle"
            type={"text"}
            sx={inputStyles}
            label="handle (ex. @riseup)"
            placeholder="handle (ex. @riseup)"
            onChange={handleChange}
            value={token.handle}
          />
        </Box>
        <Box sx={{ height: "12px" }} />
        <Box display="flex">
          <OutlinedInput
            id="ticker"
            type={"text"}
            sx={inputStyles}
            label="Ticker"
            placeholder="Ticker"
            onChange={handleChange}
            value={token.ticker}
          />
          <Box sx={{ width: "15px" }} />
          <OutlinedInput
            id="supply"
            type={"number"}
            sx={inputStyles}
            label="Supply"
            placeholder="Supply"
            onChange={handleChange}
            value={token.supply}
          />
        </Box>
        <Box sx={{ height: "12px" }} />
        <Box display="flex">
          <OutlinedInput
            id="cost"
            type={"number"}
            sx={inputStyles}
            label="Cost"
            placeholder="Cost per mint (BTC)"
            onChange={handleChange}
            value={token.cost}
          />
          <Box sx={{ width: "15px" }} />
          <OutlinedInput
            id="max"
            type={"number"}
            sx={inputStyles}
            label="Max Mint per TX"
            placeholder="Max Mint per TX"
            onChange={handleChange}
            value={token.max}
          />
        </Box>
        <Box sx={{ height: "12px" }} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            // sx={{ ...inputStyles }}
            // inputFormat="MM/dd/yyyy hh:mm a"
            label="Date of release"
            onChange={(e) => {
              if (e) {
                setToken({ ...token, date: new Date(e) });
              }
            }}
            value={token.date}
            slotProps={{
              textField: {
                sx: inputStyles,
                InputLabelProps: {
                  sx: {
                    color: "rgba(64,64,64,1)",
                  },
                },
              },
              openPickerIcon: {
                sx: {
                  color: "rgba(64,64,64,1)",
                },
              },
            }}
          />
        </LocalizationProvider>
        <Box sx={{ height: "12px" }} />
        <OutlinedInput
          id="wallet"
          type={"wallet"}
          sx={inputStyles}
          label="wallet"
          placeholder="wallet to recieve funds"
          onChange={handleChange}
          value={token.wallet}
        />
        <Box sx={{ height: "36px" }} />
        <PurpleButton
          style={{
            background:
              "linear-gradient(315deg, #8F00FF 5.26%, #FF5C00 94.74%)",
            width: "100%",
            borderRadius: "18px",
          }}
          type="submit"
          disabled={status === "loading"}
        >
          {status === "loading" ? <CircularProgress /> : "Submit"}
        </PurpleButton>
        {errorMessage}
        <Box sx={{ height: "12px" }} />
      </form>
    </Box>
  );
};

export default NewTokenForm;
