import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import React from "react";

interface QueueStatusProps {
  position: number;
}

const QueueStatus: React.FC<QueueStatusProps> = ({ position }) => {
  return (
    <Box
      minHeight={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress color="secondary" />
      <Typography color="white">You are in the queue</Typography>
      <Typography color="white">Your position: {position}</Typography>
    </Box>
  );
};

export default QueueStatus;
