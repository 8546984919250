import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  // Box,
  Checkbox,
  DialogContent,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { DatePicker } from "@mui/x-date-pickers";
import { PurpleButton } from "../Buttons/Buttons.component";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { RootState } from "../../redux/store";
import { closeApplyDialog } from "../../redux/settings.slice";
import {
  TokenApplication,
  createApplication,
} from "../../redux/tokenApplication.slice";
import { sendNotification } from "../../utils/sendNotification";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const inputStyles = {
  background: "#0E0E0F",
  backdropFilter: "blur(7px)",
  width: "100%",
  ".MuiOutlinedInput-input": {
    color: "rgba(255, 255, 255, 0.6)",
  },
  border: "1px solid rgba(255, 255, 255, 0.17)",
  borderRadius: "8px",
  color: "rgba(64,64,64,1)",

  "&::placeholder": {
    color: "rgba(64,64,64,1)",
    fontStyle: "italic",
  },
};

function ApplyDialog() {
  const isApply = useAppSelector((state: RootState) => state.settings.isApply);
  const dispatch = useAppDispatch();
  const createApplicationStatus = useAppSelector(
    (state: RootState) => state.applications.createApplication.status
  );
  const createApplicationError = useAppSelector(
    (state: RootState) => state.applications.createApplication.error
  );

  const [formData, setFormData] = React.useState<TokenApplication>({
    name: "",
    social: "",
    handle: "",
    ticker: "",
    supply: undefined,
    cost: undefined,
    max: undefined,
    date: undefined,
    marketing: false,
    listing: false,
    market: false,
    tokennomics: false,
  });

  const [fullWidth, setFullWidth] = React.useState(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleFormDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.checked,
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (createApplicationStatus !== "loading") {
      dispatch(createApplication(formData));
      // sendNotification("", "success", "Submitting application");
    }
  };

  let errorMessage: JSX.Element | null = null;
  if (createApplicationStatus === "failed") {
    errorMessage = (
      <Typography color={"red"} textAlign={"center"}>
        {createApplicationError && (createApplicationError as any).message
          ? (createApplicationError as any)?.message
          : createApplicationError}
      </Typography>
    );
  }

  return (
    <Dialog
      fullWidth={fullWidth}
      onClose={() => {
        if (isApply) {
          dispatch(closeApplyDialog());
        }
      }}
      open={isApply}
      TransitionComponent={Transition}
      sx={{
        ".MuiDialog-container": {
          backdropFilter: "blur(5px)",
        },
        ".MuiDialog-paper": {
          // background:theme.palette.primary.main,
          background:
            "linear-gradient(#0D0b10, #0D0b10) padding-box,\n        linear-gradient(60deg, #885CE7, #E8DDFF) border-box",
          color: "#eee",
          border: {
            md: ".2px solid transparent",
          },
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle
        style={{
          fontSize: fullScreen ? "20px" : "30px",
          color: "#eee",
        }}
      >
        <span
          style={{
            background: "linear-gradient(180deg, #885CE7, #E8DDFF)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: "500",
          }}
        >
          Launch
        </span>{" "}
        With Rise
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {" "}
          <OutlinedInput
            id="name"
            type={"name"}
            sx={inputStyles}
            label="Name"
            placeholder="Name Of Project"
            onChange={handleFormDataChange}
            value={formData.name}
          />
          <Box sx={{ height: "12px" }} />
          <Box display="flex">
            <FormControl fullWidth>
              <InputLabel
                sx={{ color: "rgba(64,64,64,1)" }}
                id="demo-simple-select-label"
              >
                Social
              </InputLabel>
              <Select
                sx={inputStyles}
                labelId="demo-simple-select-label"
                id="social"
                label="Social"
                onChange={(e) =>
                  setFormData({ ...formData, social: e.target.value })
                }
                value={formData.social}
              >
                <MenuItem value={"Twitter"}>Twitter</MenuItem>
                <MenuItem value={"Discord"}>Discord</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ width: "15px" }} />
            <OutlinedInput
              id="handle"
              type={"text"}
              sx={inputStyles}
              label="handle (ex. @riseup)"
              placeholder="handle (ex. @riseup)"
              onChange={handleFormDataChange}
              value={formData.handle}
            />
          </Box>
          <Box sx={{ height: "12px" }} />
          <Box display="flex">
            <OutlinedInput
              id="ticker"
              type={"text"}
              sx={inputStyles}
              label="Ticker"
              placeholder="Ticker"
              onChange={handleFormDataChange}
              value={formData.ticker}
            />
            <Box sx={{ width: "15px" }} />
            <OutlinedInput
              id="supply"
              type={"number"}
              sx={inputStyles}
              label="Supply"
              placeholder="Supply"
              onChange={handleFormDataChange}
              value={formData.supply}
            />
          </Box>
          <Box sx={{ height: "12px" }} />
          <Box display="flex">
            <OutlinedInput
              id="cost"
              type={"number"}
              sx={inputStyles}
              label="Cost"
              placeholder="Cost per mint (BTC)"
              onChange={handleFormDataChange}
              value={formData.cost}
            />
            <Box sx={{ width: "15px" }} />
            <OutlinedInput
              id="max"
              type={"number"}
              sx={inputStyles}
              label="Max Mint per TX"
              placeholder="Max Mint per TX"
              onChange={handleFormDataChange}
              value={formData.max}
            />
          </Box>
          <Box sx={{ height: "12px" }} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              // sx={{ ...inputStyles }}
              label="Date of release"
              onChange={(e) => {
                if (e) {
                  setFormData({ ...formData, date: new Date(e) });
                }
              }}
              value={formData.date}
              slotProps={{
                textField: {
                  sx: inputStyles,
                  InputLabelProps: {
                    sx: {
                      color: "rgba(64,64,64,1)",
                    },
                  },
                },
                openPickerIcon: {
                  sx: {
                    color: "rgba(64,64,64,1)",
                  },
                },
              }}
            />
          </LocalizationProvider>
          <Box sx={{ height: "12px" }} />
          <Typography>Which following would you need support in?</Typography>
          <Box sx={{ height: "12px" }} />
          <Box>
            <FormControlLabel
              label="Token Marketing Service"
              sx={{
                color: "gray",
              }}
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={formData.marketing}
                  id="marketing"
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "#eeee" },
                  }}
                />
              }
            />

            <FormControlLabel
              label="CEX Listings"
              sx={{
                color: "gray",
              }}
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={formData.listing}
                  id="listing"
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "#eeee" },
                  }}
                />
              }
            />
            <FormControlLabel
              label="Market Making"
              sx={{
                color: "gray",
              }}
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={formData.market}
                  id="market"
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "#eeee" },
                  }}
                />
              }
            />
            <FormControlLabel
              label="BRC-20 Tokenomics"
              sx={{
                color: "gray",
              }}
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={formData.tokennomics}
                  id="tokennomics"
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "#eeee" },
                  }}
                />
              }
            />
          </Box>
          <Box sx={{ height: "12px" }} />
          <PurpleButton
            style={{
              background:
                "linear-gradient(315deg, #8F00FF 5.26%, #FF5C00 94.74%)",
              width: "100%",
              borderRadius: "18px",
            }}
            type="submit"
            disabled={createApplicationStatus === "loading"}
          >
            {createApplicationStatus === "loading" ? (
              <CircularProgress />
            ) : (
              "Submit"
            )}
          </PurpleButton>
          <Box sx={{ height: "12px" }} />
          <Button
            variant="contained"
            style={{
              width: "100%",
              border: ".2px solid #885CE7",
              color: "#eee",
              borderRadius: "30px",
              background:
                createApplicationStatus === "loading" ? "grey" : undefined,
            }}
            onClick={() => {
              dispatch(closeApplyDialog());
            }}
            disabled={createApplicationStatus === "loading"}
          >
            Cancel
          </Button>
          {errorMessage}
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ApplyDialog;
