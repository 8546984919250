import React from "react";

import Typography from "@mui/material/Typography";
import QRCode from "qrcode.react";

import {
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { PurpleButton } from "../../components/Buttons/Buttons.component";
import { Token } from "../../redux/token.slice";

const PaymentComponent = ({
  handleClose,
  token,
  totalMinting,
  walletDetails,
}: {
  handleClose: () => void;
  token: Token;
  totalMinting: number;
  walletDetails:
    | {
        address: string;
        key: string;
        mnemonic: string;
      }
    | undefined;
}) => {
  return (
    <Box
      sx={{
        width: { xs: "100%", md: "50vw" },

        // background:theme.palette.primary.main,
        background:
          "linear-gradient(#0D0b10, #0D0b10) padding-box,\n        linear-gradient(60deg, #885CE7, #E8DDFF) border-box",
        color: "#eee",
        border: {
          md: ".2px solid transparent",
        },
        borderRadius: "20px",
      }}
    >
      <Box>
        <Typography variant="h6" textAlign={"center"}>
          Waiting On Payment
        </Typography>
        <Box sx={{ height: "6px" }} />
        <Typography textAlign={"center"} color={"rgba(255,255,255,.5)"}>
          orderId: 2624e41789cf12bf6d6c1a0e213e6a4c212d068c
        </Typography>
        <Box sx={{ height: "12px" }} />
        <Typography variant="h6" textAlign={"center"}>
          Total Due: {token?.cost * totalMinting} BTC
        </Typography>

        <Box sx={{ height: "20px" }} />

        <Typography variant="subtitle1">Pay With BTC</Typography>

        <Box sx={{ height: "10px" }} />

        <Table
          sx={{ minWidth: "100%", color: "red" }}
          size="small"
          aria-label="a dense table"
        >
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                color: "red",
              }}
            >
              <TableCell align="right">
                <Typography
                  style={{
                    color: "white",
                  }}
                >
                  Send to address: {walletDetails?.address}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  style={{
                    color: "white",
                  }}
                >
                  {token.wallet}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                color: "red",
              }}
            >
              <TableCell align="right">
                <Typography
                  style={{
                    color: "white",
                  }}
                >
                  Scan to Pay:
                </Typography>
              </TableCell>
              <TableCell align="left">
                <QRCode
                  value={`bitcoin:${walletDetails?.address}?amount=${
                    // @ts-ignore
                    token.cost * totalMinting
                  }`}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box sx={{ height: "25px" }} />
        <Typography>
          Please note you can leave this page once you sent your payment, you
          can check the status of of your inscritption via user dashboard, save
          your order id in case of any errors
        </Typography>
        <Box sx={{ height: "25px" }} />
        <PurpleButton
          style={{
            background:
              "linear-gradient(315deg, #8F00FF 5.26%, #FF5C00 94.74%)",
            width: "100%",
            borderRadius: "18px",
          }}
        >
          Done
        </PurpleButton>
        <Box sx={{ height: "12px" }} />
        <Button
          variant="contained"
          style={{
            width: "100%",
            border: ".2px solid #885CE7",
            color: "#eee",
            borderRadius: "30px",
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentComponent;
