import { Button, ButtonProps, useTheme } from "@mui/material";

interface ReusableButtonProps extends ButtonProps {
  // Add any additional custom props if needed
}

const GradientBorderButton: React.FC<ReusableButtonProps> = (props) => {
  const { children, ...rest } = props;
  const theme = useTheme();
  return (
    <div>
      <Button
        sx={{
          background:
            "linear-gradient(#141414, #141414) padding-box,\n        linear-gradient(60deg, #885CE7, #E8DDFF) border-box",
          color: "#eee",
          padding: "10px 30px",
          border: "2px solid transparent",
          borderRadius: "40px",
          //   display: "inline-block",
          fontSize: "12px",
          textTransform: "none",
          transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
          }),
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
        {...rest}
      >
        {children}
      </Button>
    </div>
  );
};

export const PurpleButton = (props: ReusableButtonProps) => {
  const { children, ...rest } = props;

  return (
    <Button
      variant="contained"
      style={{
        width: "100%",
        background: "#885CE7",
        color: "#eee",
        borderRadius: "30px",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default GradientBorderButton;
