import React from "react";

import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import QRCode from "qrcode.react";

import {
  DialogContent,
  Box,
  OutlinedInput,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from "@mui/material";
import { PurpleButton } from "../../components/Buttons/Buttons.component";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { RootState } from "../../redux/store";
import useFeeRate from "../../hooks/feerate.hook";
import { Token } from "../../redux/token.slice";

const inputStyles = {
  background: "#0E0E0F",
  backdropFilter: "blur(7px)",
  width: "100%",
  ".MuiOutlinedInput-input": {
    color: "rgba(255, 255, 255, 0.6)",
  },
  border: "1px solid rgba(255, 255, 255, 0.17)",
  borderRadius: "8px",
  color: "rgba(64,64,64,1)",

  "&::placeholder": {
    color: "rgba(64,64,64,1)",
    fontStyle: "italic",
  },
};

function satsToBtc(sats: any) {
  return sats / 1e8;
}

function btcToSats(btc: any) {
  return btc * 1e8;
}

const Fees = ({ fee, selectFee }: any) => {
  const { fees, loading, error } = useFeeRate();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box
      sx={{
        //   height: "10px",
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {["Economy", "Normal", "Fast"].map((a: any, i: any) => {
        return (
          <Box
            key={a}
            sx={{
              background: fee && fee.id === a ? "gray" : "#0E0E0F",
              backdropFilter: "blur(7px)",
              border: "1px solid rgba(255, 255, 255, 0.17)",
              borderRadius: "8px",
              color: "rgba(64,64,64,1)",
              width: "30%",
              cursor: "pointer",
            }}
            onClick={() =>
              selectFee({
                id: a,
                value: fees[i],
              })
            }
          >
            <Typography textAlign={"center"}>{a}</Typography>
            <Typography textAlign={"center"} color="#eee" variant="h6">
              {fees[i]}
            </Typography>
            <Typography textAlign={"center"}>sats/vb</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const MintForm = ({
  token,
  handleSubmit,
  loading,
  error,
}: {
  token: Token;
  handleSubmit: any;
  loading: boolean;
  error: string;
}) => {
  const ordinalsAddress = useAppSelector(
    (state: RootState) => state.user.ordinalsAddress
  );
  const [totalMinting, setTotalMinting] = React.useState(1);

  const theme = useTheme();
  const [fee, selectFee] = React.useState<any>(undefined);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [receiverAddress, setReceiverAddress] = React.useState(ordinalsAddress);

  const FeesTable = () => {
    if (true) return <></>;

    return (
      <Table
        //   sx={{ minWidth: 650, color: "red" }}
        size="small"
        aria-label="a dense table"
      >
        <TableBody>
          {" "}
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              color: "red",
            }}
          >
            <TableCell align="right">
              <Typography
                style={{
                  color: "white",
                }}
              >
                Minting Fee:
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                Network Fee:
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                Service Base Fee:
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography
                style={{
                  color: "white",
                }}
              >
                {btcToSats(token?.cost)} sats
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                {fee?.value} stats
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                {/* {data?.SERVICE_FEE} stats */}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                style={{
                  color: "white",
                }}
              >
                {token?.cost} BTC
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                {satsToBtc(fee.value)} BTC
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                {/* {satsToBtc(data?.SERVICE_FEE)} BTC */}
              </Typography>
            </TableCell>
          </TableRow>{" "}
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              color: "red",
            }}
          >
            <TableCell align="right">
              <Typography
                style={{
                  color: "white",
                }}
              >
                Total Fee
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography
                style={{
                  color: "white",
                }}
              >
                {/* {data?.total_fees}{" "} */}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                style={{
                  color: "white",
                }}
              >
                {/* {satsToBtc(data?.total_fees)} BTC */}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "50vw" },
        // background:theme.palette.primary.main,
        background:
          "linear-gradient(#0D0b10, #0D0b10) padding-box,\n        linear-gradient(60deg, #885CE7, #E8DDFF) border-box",
        color: "#eee",
        border: {
          md: ".2px solid transparent",
        },
        borderRadius: "20px",
      }}
    >
      <DialogTitle>
        {" "}
        <Typography variant={fullScreen ? "h5" : "h4"} sx={{ color: "#eee" }}>
          <span
            style={{
              background: "linear-gradient(180deg, #885CE7, #E8DDFF)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "500",
            }}
          >
            Mint
          </span>{" "}
          Token
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>Provide amount you want to Mint:</Typography>
        <Box sx={{ height: "12px" }} />
        <OutlinedInput
          id="outlined-adornment-password"
          type={"number"}
          sx={inputStyles}
          label="number"
          placeholder="to total minting"
          value={totalMinting}
          onChange={(e) => {
            setTotalMinting(parseInt(e.target.value));
          }}
        />
        <Box sx={{ height: "12px" }} />
        <Typography>Provide the address to receive the inscription:</Typography>
        <Box sx={{ height: "12px" }} />
        <OutlinedInput
          id="outlined-adornment-password"
          type={"name"}
          sx={inputStyles}
          label="Name"
          placeholder="Receive BTC Address"
          value={receiverAddress}
          onChange={(e) => {
            setReceiverAddress(e.target.value);
          }}
        />
        <Box sx={{ height: "12px" }} />
        <Box
          display="flex"
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography>Select the network fee you want to pay:</Typography>
          <Box sx={{ height: "10px" }} />
          <Fees fee={fee} selectFee={selectFee} />
        </Box>
        <Box sx={{ height: "12px" }} />
        {FeesTable()}
        <Box sx={{ height: "25px" }} />
        <Typography>
          Please note the inscribing transaction delivers the inscription to the
          receiving address directly.
        </Typography>
        <Box sx={{ height: "25px" }} />
        {error && <Typography color={"red"}>{error}</Typography>}
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <PurpleButton
            style={{
              background:
                "linear-gradient(315deg, #8F00FF 5.26%, #FF5C00 94.74%)",
              width: "100%",
              borderRadius: "18px",
            }}
            onClick={() => handleSubmit(receiverAddress, totalMinting)}
            disabled={totalMinting <= 0 || !receiverAddress || !fee}
          >
            Submit
          </PurpleButton>
        )}
      </DialogContent>
    </Box>
  );
};

export default MintForm;
