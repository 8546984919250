import React from "react";

import { experimentalStyled as styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Container, Divider, Skeleton, Typography } from "@mui/material";
import Iframe from "../../components/Iframe/Iframe.component";
import { Avatar } from "@mui/material";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import useGetInscriptionsByAddress from "../../hooks/useGetInscriptionsByAddress.hook";

const Profile: React.FC = () => {
  const theme = useTheme();

  return (
    <div style={{ flexGrow: 1 }}>
      <Box
        style={{
          padding: 16,
          background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
          color: "white",
          textAlign: "center",
          height: "150px",
        }}
      ></Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 24,
          marginTop: -120, // move the Avatar up by 80px
        }}
      >
        <Avatar
          alt="Profile Picture"
          src="/profile.jpg"
          style={{
            width: 160,
            height: 160,
            border: `5px solid ${theme.palette.primary.main}`,
          }}
        />
      </div>
      <Box sx={{ height: "10px" }} />
      <Typography variant="h6" textAlign={"center"}>
        bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq
      </Typography>
    </div>
  );
};

function ColorToggleButton() {
  const [alignment, setAlignment] = React.useState("web");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton
        sx={{
          color: "red",
          background: "blue",
          ".MuiToggleButton-root": {
            color: "red",
            background: "blue",
          },
        }}
        value="web"
      >
        Inscriptions
      </ToggleButton>
      <ToggleButton value="android">Activities</ToggleButton>
    </ToggleButtonGroup>
  );
}

const DashboardPage = () => {
  const { data, loading, error, fetchNextPage } = useGetInscriptionsByAddress();

  if (error)
    return (
      <Box
        sx={{
          // background: theme.palette.primary.main,
          minHeight: { xs: "90vh", md: "100vh" },
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          // background:"red",
          paddingTop: { md: "50px", xs: "50px" },
          color: "red",
          // overflow:"scroll"
        }}
      >
        <Typography variant="h4">{error.message}</Typography>
      </Box>
    );
  if (loading)
    return (
      <Box
        sx={{
          // background: theme.palette.primary.main,
          minHeight: { xs: "90vh", md: "100vh" },
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // background:"red",
          paddingTop: { md: "50px", xs: "50px" },
          color: "#eee ",
          // overflow:"scroll"
        }}
        // style={{
        //   backgroundImage: `url("${LandingImage}")`,
        // }}
      >
        <Container maxWidth="xl">
          <Profile />
          <Divider sx={{ height: "40px" }} />
          <ColorToggleButton />
          <Divider sx={{ height: "40px" }} />
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {new Array(6).fill(1).map((insc, index) => (
                <Grid item xs={2} sm={3} md={3} key={insc.inscriptionId}>
                  <Typography variant="h6" textAlign={"center"}>
                    <Skeleton sx={{ bgcolor: "grey.900" }} />
                  </Typography>
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    variant="rectangular"
                    width="100%"
                    height={200}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    );

  return (
    <Box
      sx={{
        // background: theme.palette.primary.main,
        minHeight: { xs: "90vh", md: "100vh" },
        width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // background:"red",
        paddingTop: { md: "50px", xs: "50px" },
        color: "#eee ",
        // overflow:"scroll"
      }}
      // style={{
      //   backgroundImage: `url("${LandingImage}")`,
      // }}
    >
      <Container maxWidth="xl">
        <Profile />
        <Divider sx={{ height: "40px" }} />
        <ColorToggleButton />
        <Divider sx={{ height: "40px" }} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {data.map((insc, index) => (
              <Grid item xs={2} sm={3} md={3} key={insc.inscriptionId}>
                <Typography variant="h6" textAlign={"center"}>
                  Inscription {insc.inscriptionNumber}
                </Typography>
                <Iframe
                  preview={insc.preview}
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    padding: "10px",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default DashboardPage;
